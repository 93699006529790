import { createAction, props } from '@ngrx/store';
import { Beneficiary } from '../../domain/entities/beneficiary.entity';
import {BeneficiaryResponseDTO, BeneficiaryUpSertRequestDTO} from "../../application/dtos/beneficiary.dto";

// Load Beneficiaries
export const loadBeneficiaries = createAction('[Beneficiary] Load Beneficiaries');
export const loadBeneficiariesSuccess = createAction(
    '[Beneficiary] Load Beneficiaries Success',
    props<{ beneficiaries: Beneficiary[] }>()
);
export const loadBeneficiariesFailure = createAction(
    '[Beneficiary] Load Beneficiaries Failure',
    props<{ error: any }>()
);

// Create Beneficiary
export const createBeneficiary = createAction(
    '[Beneficiary] Create Beneficiary',
    props<{ beneficiary: BeneficiaryUpSertRequestDTO }>()
);
export const createBeneficiarySuccess = createAction(
    '[Beneficiary] Create Beneficiary Success',
    props<{ beneficiary: BeneficiaryResponseDTO }>()
);
export const createBeneficiaryFailure = createAction(
    '[Beneficiary] Create Beneficiary Failure',
    props<{ error: any }>()
);

// Update Beneficiary
export const updateBeneficiary = createAction(
    '[Beneficiary] Update Beneficiary',
    props<{ beneficiary: BeneficiaryUpSertRequestDTO }>()
);
export const updateBeneficiarySuccess = createAction(
    '[Beneficiary] Update Beneficiary Success',
    props<{ beneficiary: BeneficiaryResponseDTO }>()
);
export const updateBeneficiaryFailure = createAction(
    '[Beneficiary] Update Beneficiary Failure',
    props<{ error: any }>()
);

// Delete Beneficiary
export const deleteBeneficiary = createAction(
    '[Beneficiary] Delete Beneficiary',
    props<{ beneficiary: Beneficiary }>()
);
export const deleteBeneficiarySuccess = createAction(
    '[Beneficiary] Delete Beneficiary Success',
    props<{ beneficiary: BeneficiaryResponseDTO }>()
);
export const deleteBeneficiaryFailure = createAction(
    '[Beneficiary] Delete Beneficiary Failure',
    props<{ error: any }>()
);

// Get Payee Name
export const getPayeeName = createAction(
    '[Beneficiary] Get Payee Name',
    props<{ accountNumber: string }>()
);
export const getPayeeNameSuccess = createAction(
    '[Beneficiary] Get Payee Name Success',
    props<{ accountNumber: string, payeeName: string }>()
);
export const getPayeeNameFailure = createAction(
    '[Beneficiary] Get Payee Name Failure',
    props<{ error: any }>()
);

<nav class="text-sm">
	<ol class="list-reset flex">
		<li *ngFor="let breadcrumb of items; let last = last">
			<a *ngIf="!last" [href]="breadcrumb.link">{{
				breadcrumb.title | translate
			}}</a>
			<span *ngIf="!last" class="mx-2">></span>
			<span *ngIf="last" class="text-primary font-semibold">{{
				breadcrumb.title | translate
			}}</span>
		</li>
	</ol>
</nav>

import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-breadcrumb',
	standalone: true,
	imports: [NgFor, NgIf, TranslateModule],
	templateUrl: './breadcrumb.component.html',
	styleUrl: './breadcrumb.component.css',
})
export class BreadcrumbComponent {
	@Input() items: { title: string; link?: string }[] = [
		{ title: 'Accueil', link: '#' },
		{ title: 'Virements', link: '#' },
		{ title: 'Gestion des Bénéficiaires', link: '#' },
		{ title: 'Texte' }, // Dernier élément sans lien car c'est le titre actuel
	];
}
